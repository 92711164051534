import axios from './axiosInit';

const getList = async (options,date,status, search) =>
{
    try
    {
        let url = 'finance/listing?page=' + options.value.page + '&per_page=' + options.value.itemsPerPage
        let userData = JSON.parse(localStorage.getItem('userData'));
        if(userData.role == 'boat_owner'){
            url = url + '&user_id=' + userData.id
        }
        if(date){
            url = url  + '&on_date=' + date
        }
        if(search){
            url = url  + '&search=' + search
        }
        if(status){
            url = url  + '&status=' + status
        }
        const result = await axios.get(url)
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const getcardList = async () =>
{
    try
    {
        const result = await axios.get('finance')
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

const viewFinanceList = async (id) =>
{
    try
    {
        const result = await axios.get('finance/listing?id=' + id);
        return result.data
    } catch (e)
    {
        console.log(e)
        // if (e.response && '401' == e.response.status) accountServices.logout();
    }
}

export default {
    getList,
    getcardList,
    viewFinanceList

}
